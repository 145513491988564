import Link from 'next/link'
import {useRouter} from 'next/router'
import {useContext} from 'react'
import {CartContext} from '../../../../lib/context/cart-context'
import {pathTranslations} from '../../../../path-translations'
import styles from './MobileStickyNavigation.module.css'
import {useTranslation} from 'next-i18next'

export interface MobileStickyNavigationProps {
  slideInMenuActive?: boolean
  setSlideInMenuActive?: (open: boolean) => void
}

const MobileStickyNavigation = ({
  slideInMenuActive,
  setSlideInMenuActive,
}: MobileStickyNavigationProps) => {
  const {locale} = useRouter()
  const {cartProductsCount} = useContext(CartContext)

  const {t} = useTranslation()

  const handleSlideInMenu = () => {
    setSlideInMenuActive?.(!slideInMenuActive)
  }

  return (
    <div className="w-screen bg-fixmerWhite flex h-[5rem]">
      <div className="flex h-full flex-col w-1/4 items-center justify-center">
        <Link href={pathTranslations.products[locale!]!} locale={locale!}>
          <div className="flex flex-col items-center">
            <div className={`h-6 w-8  bg-contain bg-center bg-no-repeat ${styles.products}`}></div>
            <p className="body-highlighted text-center text-fixmerGrey mt-3">
              {t('navigation.mobile.products')}
            </p>
          </div>
        </Link>
      </div>
      <Link
        href="/scan"
        type="button"
        className="w-1/4 flex h-full flex-col items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <div className={`h-6 w-8  bg-contain bg-center bg-no-repeat ${styles.scan}`}></div>
          <p className="body-highlighted text-center text-fixmerGrey mt-3">
            {t('navigation.mobile.scan')}
          </p>
        </div>
      </Link>
      <div className="w-1/4 flex h-full flex-col items-center justify-center">
        <Link href="/cart">
          <div className="flex flex-col items-center">
            <div
              className={`h-6 w-8 relative  bg-contain bg-center bg-no-repeat ${styles.shoppingCart}`}
            >
              {cartProductsCount > 0 ? (
                <div className="absolute min-w-3 min-h-3 px-1 flex justify-center items-center -right-1 -top-1  bg-fixmerRed rounded-full">
                  <p className="text-white">{cartProductsCount}</p>
                </div>
              ) : null}
            </div>
            <p className="body-highlighted text-center text-fixmerGrey mt-3">
              {t('navigation.mobile.cart')}
            </p>
          </div>
        </Link>
      </div>
      <button
        onClick={handleSlideInMenu}
        className="w-1/4 flex h-full flex-col items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <div
            className={`h-6 w-8  bg-contain bg-center bg-no-repeat ${
              !slideInMenuActive ? styles.more : styles.close
            }`}
          ></div>
          <p className="body-highlighted text-center text-fixmerGrey mt-3">
            {!slideInMenuActive ? t('navigation.mobile.more') : t('navigation.mobile.close')}
          </p>
        </div>
      </button>
    </div>
  )
}

export default MobileStickyNavigation
