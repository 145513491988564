import {signOut} from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import {useState} from 'react'
import {downloadProductCatalogue} from '../../../lib/client-download-product-catalogue'
import LoadingDots from '../../components/LoadingDots'
import {NavigationItem} from '../../types'
import styles from './HoverMenu.module.css'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

export interface HoverMenuProps {
  navItems: NavigationItem[]
  logout?: boolean
}

const HoverMenu = ({navItems, logout}: HoverMenuProps) => {
  const router = useRouter()
  const {t} = useTranslation()

  const [isDownloading, setIsDownloading] = useState(false)

  const downloadHandler = async () => {
    setIsDownloading(true)
    await downloadProductCatalogue(router.locale)
    setIsDownloading(false)
  }

  return (
    <div className="py-2 bg-white rounded-md w-full shadow-xl flex flex-col">
      {navItems.map(item => {
        if (item.type === 'link') {
          return (
            <Link key={item.description} href={item.path}>
              <div className="px-4 py-2 flex">{item.description}</div>
            </Link>
          )
        }

        if (item.type === 'action') {
          return (
            <button
              key={item.description}
              onClick={() => downloadHandler()}
              disabled={isDownloading}
            >
              <div className="px-4 py-2 flex w-full items-center justify-between">
                <span className="text-left">
                  {item.action === 'download-catalogue'
                    ? t('account.downloadCatalogue')
                    : item.description}
                </span>
                {isDownloading ? (
                  <LoadingDots />
                ) : item.iconDark ? (
                  <Image width={24} height={24} src={item.iconDark} alt={item.iconAlt ?? ''} />
                ) : null}
              </div>
            </button>
          )
        }

        return null
      })}
      {logout ? (
        <div className="border-t w-full mt-2 border-fixmerLightGrey pt-2">
          <button
            className="flex w-full py-2 px-4  group items-center justify-between"
            onClick={async () => {
              await signOut({callbackUrl: '/'})
            }}
          >
            <p>Logout</p>
            <div className={`w-6 h-6 bg-no-repeat bg-center bg-contain ${styles.arrow}`} />
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default HoverMenu
