import download from 'downloadjs'

export const downloadProductCatalogue = async (locale?: string) => {
  const response = await fetch(`/api/download-catalogue?locale=${locale ?? 'nl'}`)
  const blob = await response.blob()

  const now = new Date()

  // Date in ddmmyy
  const datestr = [
    now.getDate().toString().padStart(2, '0'),
    (1 + now.getMonth()).toString().padStart(2, '0'),
    now.getFullYear().toString().slice(-2),
  ].join('')

  const filename = `Fixmer_product_catalogue_${datestr}`

  download(blob, filename)
}
