import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {useContext} from 'react'
import {CartContext} from '../../../../lib/context/cart-context'
import useUser from '../../../../lib/hooks/use-user'
import {dateToShortString, dateToTimeString} from '../../../../lib/utitlities'
import ComponentContainer from '../../../containers/ComponentContainer'
import type {Language, NavigationItem, NavigationLinkItem} from '../../../types'
import HoverMenu from '../../HoverMenu'
import styles from './headerNavigation.module.css'
import {useQuery} from 'react-query'
import fetchCtData from '../../../../lib/commercetools/fetch-ct-data'
import {AccountQuery} from '../../../../gql-types'
import {QueryType} from '../../../../lib/commercetools/query-types'

export interface HeaderNavigationProps {
  logo: string
  logoAlt: string
  navItems: NavigationItem[]
  accountNavItems: NavigationItem[]
  languages: Language[]
  openAccountMenu?: () => void
}

const HeaderNavigation = ({
  logo,
  logoAlt,
  navItems,
  languages,
  accountNavItems,
  openAccountMenu,
}: HeaderNavigationProps) => {
  const {asPath, locale} = useRouter()
  const {t} = useTranslation('common')
  const {loggedIn, user} = useUser()
  const {cartProductsCount} = useContext(CartContext)

  // Fetch account information
  const {data: customerResponse} = useQuery(
    `customer_${user.activeBU}`,
    async () => fetchCtData<AccountQuery>(QueryType.Account),
    {
      enabled: !!user.activeBU,
    },
  )

  const userDeadline = user.deadline
    ? `${t('account.deadline')} ${dateToTimeString(
        new Date(user.deadline),
        locale!,
      )} - ${dateToShortString(new Date(user.deadline), locale!, '.')}`
    : null

  const userBasicInfo = customerResponse?.businessUnit?.name.toLowerCase() ?? ''

  const loginContent = (
    <div className="flex items-center gap-2 cursor-pointer">
      {userDeadline ? (
        <div>
          <p className="text-white font-bold text-small">{userDeadline}</p>
          <p className="text-white text-right text-x-small">
            {userBasicInfo.length > 30 ? userBasicInfo.slice(0, 27) + '...' : userBasicInfo}
            {` (${user.activeBU})`}
          </p>
        </div>
      ) : (
        <div>
          <p className="text-white font-bold text-right text-small">{user.firstName}</p>
          <p className="text-white text-right text-x-small">
            {userBasicInfo.length > 30 ? userBasicInfo.slice(0, 27) + '...' : userBasicInfo}
            {` (${user.activeBU})`}
          </p>
        </div>
      )}
      <div
        className={`h-[40px] w-[40px] bg-fixmerYellow flex justify-center items-center rounded-full`}
      >
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9997 10.5833C12.6105 10.5833 13.9163 9.2775 13.9163 7.66667C13.9163 6.05584 12.6105 4.75 10.9997 4.75C9.38884 4.75 8.08301 6.05584 8.08301 7.66667C8.08301 9.2775 9.38884 10.5833 10.9997 10.5833Z"
            stroke="#2B2E42"
            stroke-miterlimit="10"
          />
          <path
            d="M11.0003 19.7507C16.2931 19.7507 20.5837 15.46 20.5837 10.1673C20.5837 4.87459 16.2931 0.583984 11.0003 0.583984C5.7076 0.583984 1.41699 4.87459 1.41699 10.1673C1.41699 15.46 5.7076 19.7507 11.0003 19.7507Z"
            stroke="#2B2E42"
            stroke-miterlimit="10"
          />
          <path
            d="M4.4668 17.1757C5.08346 14.1257 7.77513 11.834 11.0001 11.834C14.2251 11.834 16.9085 14.1173 17.5335 17.159"
            stroke="#2B2E42"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>
  )

  const firstLinkItem = accountNavItems.find(item => item.type === 'link') as NavigationLinkItem

  return (
    <div className="w-screen py-2 z-20 bg-fixmerBlue">
      <ComponentContainer type="fullscreen" noPadding className="mt-xSmall text-white">
        <header
          className={`w-full ${
            loggedIn ? 'justify-start gap-8' : 'justify-center'
          } flex mt-8 g1024:mt-0 h-fit pb-2 items-center`}
        >
          <div
            className={`g1024:w-[6vw] ${loggedIn ? '' : 'absolute left-4'}  w-[30vw] min-w-[6rem]`}
          >
            <Link href="/" locale={locale}>
              <div className="relative h-[12vw] max-w-[10rem] g1024:max-w-[10rem] g1024:h-[4vw] w-full">
                <Image fill src={logo} alt={logoAlt} className="object-contain" />
              </div>
            </Link>
          </div>
          <ul className="hidden g1024:flex gap-[2vw] relative">
            {navItems.map(item => {
              // No action supported in main navigation so far
              if (item.type === 'action') {
                return null
              }

              const path = item.path === '/home' ? '/' : item.path
              return (
                <li className="relative group" key={item.id}>
                  <Link href={path} target={item.openInNewWindow ? '_blank' : ''} locale={locale}>
                    <div>
                      <span>{item.description}</span>
                    </div>
                    <div className="mt-2 transition-all group-hover:w-full w-0 h-[2px] bg-fixmerYellow" />
                  </Link>
                  {item.subItems && item.subItems.length > 0 ? (
                    <div className="w-[15vw] hover:flex text-fixmerBlue group-hover:flex py-3 -left-4 absolute hidden">
                      <HoverMenu navItems={item.subItems} />
                    </div>
                  ) : null}
                </li>
              )
            })}
          </ul>

          <div className="absolute flex items-center g1024:right-4 right-0 gap-4 g1200:gap-6">
            {loggedIn ? (
              <>
                {/* On desktop navigate to account page */}
                <div className="relative group">
                  <Link href={firstLinkItem.path} className="hidden g1024:block">
                    {loginContent}
                  </Link>
                  <div className="w-[15vw] hover:flex text-fixmerBlue group-hover:flex py-4 right-0 absolute hidden">
                    <HoverMenu logout navItems={accountNavItems} />
                  </div>
                </div>

                {/* On mobile slide in account menu */}
                <button
                  className="block g1024:hidden"
                  onClick={() => {
                    openAccountMenu?.()
                  }}
                >
                  {loginContent}
                </button>
              </>
            ) : (
              <Link href={`/login?url=${encodeURIComponent(asPath)}`}>
                <div className="flex items-center cursor-pointer">
                  <span className="body-like text-fixmerYellow mr-2">{t('navigation.login')}</span>
                  <div
                    className={`min-w-[1rem] min-h-[1rem] w-6 h-6 g1024:w-[1.3vw] g1024:h-[1.3vw] bg-contain bg-center ${styles.login}`}
                  ></div>
                </div>
              </Link>
            )}
            <div className="g1024:flex gap-2 hidden">
              {languages?.map(language => {
                return (
                  <span
                    key={language.code}
                    className={`uppercase body-like  ${
                      language.active ? 'text-fixmerYellow' : 'text-fixmerGrey'
                    }`}
                  >
                    <Link href={language.link} locale={language.code}>
                      {language.code}
                    </Link>
                  </span>
                )
              })}
            </div>
            <Link href={user.activeBU ? '/cart' : '/login?url=/cart'}>
              <div
                className={`min-w-[1.5rem] relative min-h-[1.5rem] w-6 h-6 g1024:w-[1.3vw] g1024:h-[1.3vw] bg-contain hidden g1024:block bg-center ${styles.cart}`}
              >
                {cartProductsCount > 0 ? (
                  <div className="absolute min-w-3 min-h-3 px-1 flex justify-center items-center -right-1 -top-1.5  bg-fixmerRed rounded-full">
                    <p className="text-[0.6vw]">{cartProductsCount}</p>
                  </div>
                ) : null}
              </div>
            </Link>
          </div>
        </header>
      </ComponentContainer>
    </div>
  )
}

export default HeaderNavigation
