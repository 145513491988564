import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'
import type {Language, NavigationItem} from '../../types'
import NavSlideInMenu from '../NavSlideInMenu'
import HeaderNavigation from './HeaderNavigation'
import MobileStickyNavigation from './MobileStickyNavigation'

export interface MainNavigationProps {
  logo: string
  logoAlt?: string
  navItems: NavigationItem[]
  accountNavItems: NavigationItem[]
  languages: Language[]
  menuLogo?: string
  menuDarkMode?: boolean
  loggedIn?: boolean
}

const MainNavigation = ({
  logo,
  logoAlt,
  navItems,
  accountNavItems,
  menuLogo,
  languages,
}: MainNavigationProps) => {
  const [slideInMenuOpen, setSlideInMenuOpen] = useState(false)
  const [accountInMenuOpen, setAccountInMenuOpen] = useState(false)

  const router = useRouter()

  useEffect(() => {
    setSlideInMenuOpen(false)
    setAccountInMenuOpen(false)
  }, [router.asPath])

  const toggleSlideInMenu = (open: boolean) => {
    if (!open) {
      setSlideInMenuOpen(false)
      setAccountInMenuOpen(false)
    } else {
      setSlideInMenuOpen(true)
    }
  }

  const sortedLanguages = languages?.sort((a, b) => b.code.localeCompare(a.code))

  return (
    <nav className="top-0 relative g768:z-40 g1024:sticky">
      <HeaderNavigation
        logo={logo}
        logoAlt={logoAlt || 'logo'}
        navItems={navItems}
        languages={sortedLanguages}
        accountNavItems={accountNavItems}
        openAccountMenu={() => {
          setAccountInMenuOpen(true)
        }}
      />
      <div className="fixed bottom-0 z-50 g1024:hidden">
        <MobileStickyNavigation
          slideInMenuActive={slideInMenuOpen || accountInMenuOpen}
          setSlideInMenuActive={toggleSlideInMenu}
        />
      </div>
      <div
        className={`fixed z-40 duration-300 top-0 ease-in-out block g1024:hidden ${
          slideInMenuOpen ? 'translate-x-0' : 'translate-x-[100vw]'
        }`}
      >
        <NavSlideInMenu
          logo={menuLogo ?? logo}
          logoAlt={logoAlt ?? 'logo'}
          navItems={navItems}
          languages={sortedLanguages}
          setSlideInMenuActive={setSlideInMenuOpen}
        />
      </div>

      <div
        className={`fixed z-50 top-0 duration-300 ease-in-out block g1024:hidden ${
          accountInMenuOpen ? 'translate-x-0' : 'translate-x-[100vw]'
        }`}
      >
        <NavSlideInMenu
          logo={logo}
          logoAlt={logoAlt ?? 'logo'}
          navItems={accountNavItems}
          setSlideInMenuActive={setAccountInMenuOpen}
          darkMode
          showLogout
        />
      </div>
    </nav>
  )
}

export default MainNavigation
