import Head from 'next/head'
import {useRouter} from 'next/router'
import FooterNavigation from '../components/navigation/FooterNavigation'
import MainNavigation from '../components/navigation/MainNavigation'
import useUser from '../lib/hooks/use-user'
import type {TranslatedPath} from '../lib/page-props'
import {getNavigationItems} from '../lib/storyblok-api'
import logoRed from '../public/assets/logo-red.svg'
import logo from '../public/assets/logo.svg'
import type {FooterStoryblok, NavigationStoryblok} from '../storyblok-types/component-types-sb'

export interface LayoutProps {
  navigation: NavigationStoryblok
  accountNavigation: NavigationStoryblok
  footer: FooterStoryblok
  translatedPaths: TranslatedPath[]
}

const Layout: React.FC<LayoutProps> = ({
  children,
  navigation,
  accountNavigation,
  footer,
  translatedPaths,
}) => {
  const router = useRouter()
  const {user} = useUser()

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <MainNavigation
        logo={logo}
        menuLogo={logoRed}
        navItems={getNavigationItems(navigation, router.locale!, user.activeBU)}
        accountNavItems={getNavigationItems(accountNavigation, router.locale!, user.activeBU)}
        languages={translatedPaths?.map(path => ({
          code: path.lang,
          active: router.locale === path.lang,
          link: path.path ?? '',
        }))}
      />
      <main className="min-h-[50vh] mt-4 g768:min-h-[60vh]">{children}</main>
      <FooterNavigation
        telephone={footer.telephone}
        logoAlt="logo"
        mail={footer.mail}
        copyright={footer.copyright}
        socialIcons={
          footer.social_icons?.map(item => ({
            icon: item.icon.filename,
            alt: item.alt ?? 'icon',
            path: item.path?.url === '' ? `/${item.link?.story?.full_slug}` : item.path?.url,
            openInNewWindow: item.link?.story.target ?? item.path?.target,
          })) ?? []
        }
        navItems={getNavigationItems(footer, router.locale!, user.activeBU)}
        logo={logo}
        address={footer.address?.split('\n')}
      />
    </>
  )
}

export default Layout
