import Image from 'next/image'
import Link from 'next/link'
import {Dispatch, SetStateAction, useState} from 'react'
import ComponentContainer from '../../containers/ComponentContainer'
import type {Language, NavigationItem} from '../../types'

import {signOut} from 'next-auth/react'
import {useTranslation} from 'next-i18next'
import {downloadProductCatalogue} from '../../../lib/client-download-product-catalogue'
import LoadingDots from '../../components/LoadingDots'
import styles from './SlideInMenu.module.css'
import {useRouter} from 'next/router'

export interface NavSlideInMenuProps {
  logo?: string
  logoAlt?: string
  languages?: Language[]
  navItems: NavigationItem[]
  darkMode?: boolean
  showLogout?: boolean
  setSlideInMenuActive: Dispatch<SetStateAction<boolean>>
}

const NavSlideInMenu = ({
  logo,
  logoAlt,
  languages,
  navItems,
  setSlideInMenuActive,
  darkMode,
  showLogout,
}: NavSlideInMenuProps) => {
  const {t} = useTranslation('common')
  const router = useRouter()

  const closeSlideInMenu = () => {
    setSlideInMenuActive(false)
  }

  const [isDownloading, setIsDownloading] = useState(false)

  const downloadHandler = async () => {
    setIsDownloading(true)
    await downloadProductCatalogue(router.locale)
    setIsDownloading(false)
  }

  return (
    <div className={`w-screen h-screen ${darkMode ? 'bg-fixmerBlue' : 'bg-fixmerWhite'}`}>
      <ComponentContainer noPadding type="fullscreen">
        <>
          <div className="w-full mt-12 flex justify-between items-center">
            <div className="w-[30vw] min-w-[6rem]">
              {logo && logoAlt ? (
                <div className="relative h-[12vw] max-w-[10rem] w-full">
                  <Image fill src={logo} alt={logoAlt} className="object-contain" />
                </div>
              ) : null}
            </div>
            <button
              type="button"
              onClick={closeSlideInMenu}
              className={`w-7 h-7 bg-contain bg-center z-40 ${
                darkMode ? styles.closeDark : styles.close
              }`}
              aria-label="close"
            />
          </div>
          <div className="flex gap-4 mt-8 px-2">
            {languages?.map(language => {
              return (
                <span
                  key={language.code}
                  className={`h4-like uppercase ${
                    language.active ? 'text-fixmerRed' : 'text-fixmerGrey'
                  }`}
                >
                  <Link href={language.link} locale={language.code}>
                    {language.code}
                  </Link>
                </span>
              )
            })}
          </div>
          <ul className={`px-2 overflow-scroll noScrollbar h-[65vh]`}>
            {navItems?.map(item => {
              return (
                <li
                  key={item.id}
                  className={`mt-6 h2-like ${darkMode ? 'text-fixmerWhite' : 'text-fixmerBlue'}`}
                >
                  <>
                    {item.type === 'link' ? (
                      <>
                        <Link href={item.path}>{item.description}</Link>
                        {item.subItems ? (
                          <div className="my-6">
                            {item.subItems.map(subItem => {
                              return (
                                <p className="m-4" key={subItem.id}>
                                  <Link href={subItem.path}>{subItem.description}</Link>
                                </p>
                              )
                            })}
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {item.type === 'action' ? (
                      <button
                        className="flex w-full items-center justify-between"
                        onClick={() => downloadHandler()}
                        disabled={isDownloading}
                      >
                        <span>{item.description}</span>
                        {isDownloading ? (
                          <LoadingDots light />
                        ) : item.iconLight ? (
                          <Image
                            className="z-50"
                            width={32}
                            height={32}
                            src={item.iconLight}
                            alt={item.iconLight}
                          />
                        ) : null}
                      </button>
                    ) : null}
                  </>
                </li>
              )
            })}
          </ul>

          {showLogout ? (
            <div className="border-t border-fixmerGrey">
              <button
                onClick={async () => {
                  await signOut({callbackUrl: '/'})
                }}
                className={`px-2 mt-8 flex w-full items-center justify-between text-fixmerGrey text-h4 font-bold`}
              >
                <span>{t('navigation.logout')}</span>
                <span className={`w-4 h-4 bg-contain bg-center bg-no-repeat ${styles.arrow}`} />
              </button>
            </div>
          ) : null}
        </>
      </ComponentContainer>
    </div>
  )
}

export default NavSlideInMenu
