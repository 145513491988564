import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import type {NavigationItem} from '../../types'

export interface FooterNavigationProps {
  logo: string
  logoAlt: string
  address?: string[]
  telephone?: string
  mail?: string
  copyright?: string
  socialIcons?: {icon: string; alt: string; path: string; openInNewWindow?: boolean}[]
  navItems?: NavigationItem[]
}

const FooterNavigation = ({
  logo,
  logoAlt,
  address,
  mail,
  telephone,
  copyright,
  socialIcons,
  navItems,
}: FooterNavigationProps) => {
  const {t} = useTranslation('common')
  return (
    <footer className="bg-fixmerBlue g768:p-8 w-full min-h-[30rem] g1024:min-h-fit flex g768:flex-row items-center flex-col text-fixmerWhite">
      <div className="p-8">
        <div className="g768:w-[10vw] w-[35vw] min-w-[6rem]">
          <div className="relative h-[15vw] g768:max-w-[10rem] g768:h-[4vw] w-full">
            <Image fill src={logo} alt={logoAlt} className="object-contain" />
          </div>
        </div>
        <div className="flex items-center justify-center g768:hidden gap-3 mt-4">
          <p className="g1024:text-small "> {t('navigation.followUs')}</p>
          {socialIcons?.map((social: {icon: string; alt: string; path: string}) => {
            return (
              <Link href={social.path} key={social.alt}>
                <div className="relative w-4 h-4">
                  <Image fill src={social.icon} alt={social.alt} className="object-contain" />
                </div>
              </Link>
            )
          })}
        </div>
      </div>
      <div className="g1024:flex-row w-full justify-between flex-col items-center flex">
        <div className="w-full text-body flex border-t g768:border-b-0 border-fixmerDarkGrey g768:border-t-0 border-b p-6">
          <div className="w-1/2 g768:w-fit g768:items-start flex-col flex items-center ">
            <div className="flex flex-col g768:flex-row flex-wrap g768:items-center g768:gap-2 gap-1">
              {address?.map((line, index) => {
                return (
                  <p key={line} className="flex g1024:text-small">
                    {line}
                    <span className="hidden g768:block">
                      {index !== address.length - 1 ? ',' : ''}
                    </span>
                  </p>
                )
              })}
            </div>
            {socialIcons && socialIcons.length > 0 ? (
              <div className="items-center g768:flex hidden gap-3 mt-4">
                <p className="g1024:text-small"> {t('navigation.followUs')}</p>
                {socialIcons?.map(social => {
                  return (
                    <Link
                      href={social.path}
                      key={social.alt}
                      target={social.openInNewWindow ? '_blank' : ''}
                    >
                      <div className="relative w-4 g1680:w-[1vw] g1680:h-[1vw] h-4">
                        <Image fill src={social.icon} alt={social.alt} className="object-contain" />
                      </div>
                    </Link>
                  )
                })}
              </div>
            ) : null}
          </div>
          <div className="w-1/2 g768:w-fit g768:ml-[2vw] flex-col flex items-center">
            <div className="flex flex-col g768:flex-row flex-wrap g768:items-center g768:gap-2 gap-1">
              <p className="flex g1024:text-small">
                T: {telephone} <span className="hidden g768:block">, </span>
              </p>
              <p className="g1024:text-small"> M: {mail} </p>
            </div>
          </div>
        </div>
        <div className="w-full g768:w-full g768:p-6 g768:items-start g768:flex-row g1024:p-0 g1024:items-center g1024:flex-col-reverse g1024:w-[25vw] py-6 flex gap-4 flex-col items-center">
          <div className="flex gap-x-8 gap-y-4 flex-wrap">
            {navItems?.map(item => {
              //Action items not supported in foorter
              if (item.type === 'action') {
                return null
              }

              return (
                <Link key={item.id} target={item.openInNewWindow ? '_blank' : ''} href={item.path}>
                  <p className="pb-2 g1024:text-small border-b flex justify-center min-w-[5rem] border-b-fixmerYellow">
                    {item.description}
                  </p>
                </Link>
              )
            })}
          </div>
          <p className="g1024:text-small">{copyright}</p>
        </div>
      </div>
    </footer>
  )
}

export default FooterNavigation
